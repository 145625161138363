import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { first } from 'rxjs/operators';
import { ErrorStateMatcher, MatDialogRef } from '@angular/material';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';


  license:boolean;

  matcher = new MyErrorStateMatcher();


  constructor(
    private formBuilder: FormBuilder,

    private router: Router,
    private authenticationService: UserService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      retype_password: ['', [Validators.required, this.checkPasswords]],
      license: [false, Validators.required],
    });

    // reset login status
    this.authenticationService.logout();
  }


  checkPasswords(AC: FormControl) { // here we have the 'passwords' group


    let dataForm = AC.parent;
    if (!dataForm) return null;



    let pass = dataForm.get("password").value;
    let confirmPass = dataForm.get("retype_password").value;
    const ret = pass === confirmPass ? null : { notSame: true };

    return ret;
  }

  // // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;


    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.create(this.f.username.value, this.f.password.value,this.f.license.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(["/login"]);
          this.loading = false;
          //this.dialogRef.close();

        },
        error => {
          console.log("error,", error);
          this.error = error;
          this.loading = false;
        });
  }
}
