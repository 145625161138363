import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';



import { ConfimMessageComponent, ConfimrMessageDefinition, eDialogTypeConfirm } from '../shared/confim-message/confim-message.component';
import { HttpClient } from '@angular/common/http';
import { eAppType, MailInfo } from '../models';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends ApiService {







  constructor(private snackBar: MatSnackBar, private translateService: TranslateService, http: HttpClient, private dialog: MatDialog) {
    super(http);
    this.controller = "common";
  }



  getProjectTranslation(text: eAppType): string {
    switch (text) {
      case eAppType.WebApp:
        return this.translate('Types.WebApp');
      case eAppType.Reportinf:
        return this.translate('Types.Reporting');
      case eAppType.DesktoApp:
        return this.translate('Types.DesktopApp');
      case eAppType.MobileApp:
        return this.translate('Types.MobileApp');
      case eAppType.DataScience:
        return this.translate('Types.DataScience');
      case eAppType.Eshop:
        return this.translate('Types.Eshop');
      case eAppType.CompanyProcess:
        return this.translate('Types.CompanyProcess');
        case eAppType.Hardware:
          return this.translate('Types.Hardware');
          case eAppType.Tool:
            return this.translate('Types.Tool');
    }

    return  "";
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }

  toast(headr: string, body: string) {

    this.snackBar.open(body, headr, {
      duration: 3000,
      verticalPosition: 'top'
    });
  }


  shovConfirmDialog(message: string) {
    let conf = new ConfimrMessageDefinition();
    conf.type == eDialogTypeConfirm.OK;
    conf.message = message;

    let dialogRef = this.dialog.open(ConfimMessageComponent, {
      data: conf,
      width: '600px',
    });


    dialogRef.afterClosed().subscribe();

  }

  sendEmail(mail: MailInfo): Observable<boolean> {
    const api = "sendemail";

    return this.post<boolean>(api, mail);
  }


}
