import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../users/user.service';
import { UserDisplay, FreelancingDisplay, FreelancingDisplayAttribute, eFreelanceRateType } from '../users/user.model';
import { FilterModel, DevelopersFilter } from '../shared/filter.model';
import { PageEvent, MatChipInputEvent, MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent, MatPaginator } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Utils } from '../utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss']
})
export class DevelopmentComponent implements OnInit {

  developerers: FreelancingDisplay[];

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  sort = 1;

  visible = true;
  selectable = true;
  removable = true;

  // MatPaginator Output
  pageEvent: PageEvent;
  filteredAttributes: Observable<FreelancingDisplayAttribute[]>;
  fruitCtrl = new FormControl();

  userKnowledges: FreelancingDisplayAttribute[] = [];


  @ViewChild('fruitInput', { static: true }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  //@ViewChild('trigger',{static:true}) autocomplete: MatAutocompleteTrigger;

  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  filterModel: DevelopersFilter;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {

    this.filteredAttributes = this.userService.getKnowledge();

    this.filterModel = new DevelopersFilter();
    this.filter();

  }



  filter() {
    ;
    this.filterModel.skils = this.userKnowledges.map(x => x.estimateAttributeId);
    if (this.pageEvent !== undefined) {
      this.filterModel.take = this.pageEvent.pageSize;

      this.filterModel.page = this.pageEvent.pageIndex;
    }
    this.filterModel.sortColl = Number(this.sort);
    this.userService.getDevelopers(this.filterModel).subscribe(x => { this.developerers = x.entities; this.length = x.maxRows; });

  }

  GetRateDesc(rate: eFreelanceRateType) {
    return Utils.GetRateTypeString(rate);

  }

  pageChange(event: PageEvent) {
    this.pageEvent = event;
    this.filter();
  }

  contact(id: number) {


    this.userService.contact(id);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    // if ((value || '').trim()) {
    //   this.fruits.push(value.trim());
    // }

    // Reset the input value
    if (input) {
      input.value = '';
    }



  }


  openAutocomplete(): void {
    this.fruitInput.nativeElement.focus();
    this.autocomplete.openPanel();

  }

  remove(attribute: FreelancingDisplayAttribute): void {
    const index = this.userKnowledges.indexOf(attribute);

    if (index >= 0) {
      this.userKnowledges.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.userKnowledges.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  scrollTo(id: string) {




    this.router.navigate(["/contact"]);

  }

}


