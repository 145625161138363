import { Component, OnInit, Input, ElementRef, ViewChild, Attribute } from '@angular/core';
import { FreelancingDisplay, FreelancingDisplayAttribute } from '../users/user.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserSettingsComponent } from '../users/user-settings/user-settings.component';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-user-freelancing',
  templateUrl: './user-freelancing.component.html',
  styleUrls: ['./user-freelancing.component.scss']
})
export class UserFreelancingComponent implements OnInit {

  @Input() freelancing: FreelancingDisplay;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredAttributes: Observable<FreelancingDisplayAttribute[]>;


  userKnowledges: FreelancingDisplayAttribute[] = [];


  @ViewChild('fruitInput', { static: true }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  //@ViewChild('trigger',{static:true}) autocomplete: MatAutocompleteTrigger;

  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;

  constructor(private userService: UserService) {


  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    // if ((value || '').trim()) {
    //   this.fruits.push(value.trim());
    // }

    // Reset the input value
    if (input) {
      input.value = '';
    }



  }


  openAutocomplete(): void {
    this.fruitInput.nativeElement.focus();
    this.autocomplete.openPanel();
 
  }

  remove(attribute: FreelancingDisplayAttribute): void {
    const index = this.freelancing.freelancingAttributes.indexOf(attribute);

    if (index >= 0) {
      this.userKnowledges.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.freelancing.freelancingAttributes.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.allKnowledge.filter(atr => atr.toLowerCase().indexOf(filterValue) === 0);
  // }



  ngOnInit() {

    this.filteredAttributes = this.userService.getKnowledge();
    this.userKnowledges = this.freelancing.freelancingAttributes;

  }

}
