import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-it-manager',
  templateUrl: './it-manager.component.html',
  styleUrls: ['./it-manager.component.scss']
})
export class ItManagerComponent implements OnInit {

  constructor(private animateScrollService: NgAnimateScrollService,) { }

  ngOnInit() {
  }

  scrollTo(id: string) {



    this.animateScrollService.scrollToElement(id, 750);

  }

}
