import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../user.service';

import { MyErrorStateMatcher } from '../register/register.component';
import { CommonService } from 'src/app/services/common.service';
import { MatDialogRef } from '@angular/material';




@Component({
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    @Input()
    license:boolean;

    matcher = new MyErrorStateMatcher();

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private dialogRef: MatDialogRef<LoginComponent>,
        private commonService: CommonService,
        private authenticationService: UserService) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            license: [false, Validators.required],
            passphrase: ['', Validators.required],
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/definition-list';
    }

    // // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    login() {
        this.submitted = true;

        // stop here if form is invalid
        if (!this.f.username.valid || !this.f.password.valid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value,this.f.license.value).pipe(first())
            .subscribe(
                data => {
                    this.loading = false;
                    if(this.dialogRef)
                    {
                        
                        this.dialogRef.close();
                    }
                    this.router.navigate(["/home"]);
                  

                },
                error => {
                    console.log("error,", error);
                    this.error = error;
                    this.loading = false;
                });



    }

    resetPassword() {
        this.submitted = true;

        // stop here if form is invalid
        if (!this.f.username.valid) {
            this.f.username.markAsDirty();
            return;
        }


        this.loading = true;
        this.authenticationService.renewPassword(this.f.username.value)
            .pipe(first())
            .subscribe(
                data => {
                    var header = this.commonService.translate('Contact.Email');
                    var body = this.commonService.translate('User.ResetPasswordEmail');
                    this.commonService.toast(header, body);
                    this.loading = false;
                },
                error => {
                    this.error = error;
                    // var header = this.commonService.translate('Contact.Email');
                    // var body = this.commonService.translate('User.ResetPasswordEmail');
                    // this.commonService.toast(header,body);
                    this.loading = false;
                });
    }



    register()
    {
        this.router.navigate(["/register"]);
        this.dialogRef.close();
    }


}