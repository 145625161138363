import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogArticleComponent } from './blog/blog-article/blog-article.component';
import { HomeComponent } from './home/home.component';
import { BlogArticlesListComponent } from './blog/blog-articles-list/blog-articles-list.component';
import { LoginComponent } from './users/login/login.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';
import { DevelopmentComponent } from './development/development.component';
import { MessageCenterComponent } from './message-center/message-center.component';
import { ContactComponent } from './contact/contact.component';
import { RegisterComponent } from './users/register/register.component';
import { PriceCalculatorComponent } from './price-calculator/price-calculator.component';

const routes: Routes = [];

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'examples', component: BlogArticlesListComponent
  },
  {
    path: 'projects', component: ProjectsComponent
  },
  // {
  //   path: 'projects/:id', component: ProjectDetailComponent
  // },
  {
    path: 'projects/:fullUrl', component: ProjectDetailComponent
  },
  {
    path: 'user/:id', component: UserDetailComponent
  },
  {
    path: 'contact', component: ContactComponent
  },
  {
    path: 'projects', component: ProjectsComponent
  },
  {
    path: 'project-created', component: MessageCenterComponent, data :{ messageType:'1'}
  },
  {
    path: 'offer-created', component: MessageCenterComponent, data :{ messageType:'2'}
  },
  {
    path: 'developer-contacted', component: MessageCenterComponent, data :{ messageType:'3'}
  },
  {
    path: 'user-registered', component: MessageCenterComponent, data :{ messageType:'4'}
  },
  {
    path: 'offer-accepted', component: MessageCenterComponent, data :{ messageType:'6'}
  },
  {
    path: 'request-contact', component: MessageCenterComponent
  },
  
  {
    path: 'developers', component: DevelopmentComponent
  },
  {
    path: 'aboutUs', component: HomeComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'register', component: RegisterComponent
  },
  {
    path: 'settings', component: UserSettingsComponent
  },

  {
    path: 'estimate-application-costs', component: HomeComponent, data :{ type:'1'}
  },
  {
    path: 'custom-reporting', component: HomeComponent, data :{ type:'5'}
  },
  {
    path: 'create-social-network', component: HomeComponent, data :{ type:'3'}
  },
  {
    path: 'estimate-mobile-app-costs', component: HomeComponent, data :{ type:'2'}
  },
  {
    path: 'extract-data', component: HomeComponent, data :{ type:'4'}
  },
  {
    path: 'create-eshop', component: HomeComponent, data :{ type:'10'}
  },
  {
    path: 'optimalize-process', component: HomeComponent, data :{ type:'6'}
  },
  {
    path: 'control-hardware', component: HomeComponent, data :{ type:'7'}
  },
  {
    path: 'create-software-tool', component: HomeComponent, data :{ type:'8'}
  },


  {
    path: 'examples/:seo_url', component: BlogArticlesListComponent
  },
  { path: '**', component: HomeComponent 
},

];



@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, useHash: false} // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }


