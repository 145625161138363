import { Component, OnInit, ViewChild, Input, Output, EventEmitter, HostListener, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { MatRadioChange, MatCheckboxChange, MatStepper, MatDialog } from '@angular/material';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { ApiService } from '../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { eAppType } from '../models';
import { ActivatedRoute } from '@angular/router';
import { SendOfferComponent } from '../send-offer/send-offer.component';
import { QuestionService } from '../services/question.service';





export class Question {

  constructor() {

  }

  questionId: number;
  text: string;
  answers: Answer[]
  stepValue: number;
  multiSelect: boolean;
  questionInfo: string;
  note: string;
  offerValue: number;
  offerNote: string;
}

export class Answer {
  value: number;
  caption: string;
  tooltip: string;
  selected: boolean;
  deselectOther: boolean;
}


@Component({
  selector: 'app-price-calculator',
  templateUrl: './price-calculator.component.html',
  styleUrls: ['./price-calculator.component.scss']
})
export class PriceCalculatorComponent implements OnInit {

  @Input() selectedAppType: eAppType;

  
  @Input() outAppType: eAppType;

  @Input()
  steps: Question[];
  @Output() stepsChange = new EventEmitter();

  result: number = 0;

  mobile: boolean;

  @Input()
  inputMessage: string;
  @Output() inputMessageChange = new EventEmitter();

@Input() type:eAppType;

  @ViewChild("stepper", { static: false }) stepper: MatStepper;

  constructor(private api: QuestionService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private animateScrollService: NgAnimateScrollService,
    private cdr: ChangeDetectorRef, route: ActivatedRoute) {

    var type = route.snapshot.queryParamMap.get("type");

this.outAppType = eAppType.Unknown;
    
    // route.queryParams.subscribe(params => {
      // });


      
    if (type != null) {
      this.selectedAppType = Number(type);
      
    }
    else {
      this.selectedAppType = eAppType.Unknown;
      

    }






    this.IsMobile();
  }


  private changedType() {
    this.result = 0;
    this.api.getQuestions(this.selectedAppType).subscribe(data => {

      let stp = data;
      let i = 1;

      if (stp != undefined) {

        stp.forEach(st => { st.questionId = 1; i = i + 1; })
        this.steps = stp;
        this.result = 0;
        this.steps.forEach(st => {
          st.text = this.translate.instant(st.text);
          st.answers.forEach(ans => {
            ans.caption = this.translate.instant(ans.caption);
            ans.selected = false;
          })
        }

        );
      }
    }, error => console.log(error));
  }

  private IsMobile() {
    this.mobile = window.innerWidth < 420;
  }

  ngOnInit() {
    if (this.outAppType != eAppType.Unknown)
    {
    console.log(this.outAppType)
        this.selectedAppType = this.outAppType;
    }

    this.changedType();
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.IsMobile();
  }


  goToLastStep()
  {
    this.stepper.selectedIndex = this.stepper.steps.length-1;

  }


  makeOffer() {
    let dialogRef = this.dialog.open(SendOfferComponent, {

      width: '600px',
      data: {

        "steps": this.steps,
        "projectType":this.selectedAppType
      }

    });


    dialogRef.afterClosed().subscribe(
      data => {

        if (data != null) {




   


        }

      }
    );

  }

  scrollTo(id: string) {

    if (this.selectedAppType != eAppType.Unknown) {




      var res: string = "\n\n\n\n";

      this.steps.forEach(step => {
        if (step.multiSelect) {

          var sel2 = step.answers.filter(x => x.selected);
          if (sel2 != undefined && sel2.length > 0) {

            res = `${res}\n${step.text}:  ${sel2.map(x => x.caption).join(", ")}\n`;
          }
        }
        else {

          var sel = step.answers.find(x => x.selected);
          if (sel != undefined) {

            res = `${res}\n${step.text}:  ${sel.caption}\n`;
          }
        }


      }
      );
      this.inputMessage = res;

      this.inputMessageChange.emit(this.inputMessage);
      this.stepsChange.emit(this.steps);
    }
    this.animateScrollService.scrollToElement(id, 750);

  }

  public changeType(type: eAppType) {
    this.selectedAppType = type;

    this.changedType();
  }

  public recalculateRadio(event: MatRadioChange, step: Question) {

    step.stepValue = event.value;

    step.answers.forEach(ans => ans.selected = ans.value == step.stepValue);
    var res = 0;
    this.steps.forEach(st => { res += st.stepValue; });

    this.result = res;
    this.stepper.next();

  }

  public recalculateCheck(event: MatCheckboxChange, step: Question, option: Answer) {

    option.selected = event.checked;

    let nextStep = false;
    if (option.deselectOther) {

      step.answers.forEach(ans => {
        if (ans.caption != option.caption) {

          ans.selected = false;
        }

      });
      nextStep = true;
    }
    else {
      step.answers.forEach(ans => {
        if (ans.deselectOther == true) {

          ans.selected = false;
        }
      });
    }

    var medziVysl = 0;
    step.answers.filter(option => option.selected).forEach(suma => medziVysl += suma.value);

    step.stepValue = medziVysl;
    let res = 0;
    this.steps.forEach(st => { res += st.stepValue; });

    this.result = res;
    this.cdr.detectChanges();
    if (nextStep) {
      this.stepper.next();
    }

  }


}
