import { Component, OnInit, Input } from '@angular/core';
import { Question } from '../price-calculator/price-calculator.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { eAppType } from '../models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Input()
  inputMessage:string;


  @Input() steps:Question[];

  selectedAppType:eAppType;

  constructor(private translate: TranslateService,private activRoute :ActivatedRoute) { 

  this.selectedAppType = eAppType.Unknown;
  }

  ngOnInit() {


    this.activRoute.data.subscribe(data => {
      //console.log(data);
           if(data.type !=null)
           {
    
             this.selectedAppType = Number(data.type); 
            }
          });
  }

}
