import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MyErrorStateMatcher } from '../register/register.component';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { LogedUserInfo, UserSettings } from '../user.model';
import { Console } from 'console';

@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {


  @Input() user:LogedUserInfo;


  userSettings:UserSettings;


  passwordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  matcher = new MyErrorStateMatcher();


  constructor(private formBuilder: FormBuilder,
    private commonService:CommonService,
    private router: Router,

    private userService: UserService) { 


      this.userSettings = new UserSettings();

    }

  ngOnInit() {


      this.userService.getSettings().subscribe(x => {
        this.userSettings = x;

        
      });




    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      retype_password: ['', [Validators.required, this.checkPasswords]]
    });

  }

  saveSettings()
  {
      this.userService.saveSettings(this.userSettings).subscribe(x=>  this.userSettings =x);
      
  }

  checkPasswords(AC: FormControl) { // here we have the 'passwords' group


    let dataForm = AC.parent;
    if (!dataForm) return null;



    let pass = dataForm.get("password").value;
    let confirmPass = dataForm.get("retype_password").value;
    const ret = pass === confirmPass ? null : { notSame: true };

    return ret;
  }

  get f() { return this.passwordForm.controls; } 

  onPasswordSubmit() {
      this.submitted = true;


      
      // stop here if form is invalid
      if (this.passwordForm.invalid) {
        return;
      }
   
    this.loading = true;
    this.userService.changePassword( this.f.password.value)
        .pipe(first())
        .subscribe(
            data => {
               /// this.router.navigate(["/login"]);
               var trHead = this.commonService.translate('User.Settings');
               var trBody = this.commonService.translate('Settings.PasswordChanged');
               this.commonService.toast(trHead,trBody);
               this.loading = false;
            },
            error => {
                console.log("error,", error);
                this.error = error;
                this.loading = false;
            });
  }

}
