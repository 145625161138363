import { Component, OnInit, Input } from '@angular/core';

import { BlogArticle } from '../blog-article.model';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
declare var require: any;


@Component({
  selector: 'app-blog-article',
  templateUrl: './blog-article.component.html',
  styleUrls: ['./blog-article.component.scss']
})
export class BlogArticleComponent implements OnInit {

  @Input()
  selfComponent: boolean;

  constructor(private blogService: ApiService, private acitvatedRoute: ActivatedRoute, private titleService: Title, private meta: Meta) {

    this.article = new BlogArticle();
    this.selfComponent = true;
  }

  @Input() article: BlogArticle;


  ngOnInit() {
    this.acitvatedRoute.params.subscribe(params => {
      let id = params['seo_url'] as string;


      if (id != undefined && id.length > 0) {

        this.blogService.getArticle(id).subscribe(res => {



          this.article = this.mapArticle(res);

          this.titleService.setTitle("EstimateAppPrice.com - " + this.article.name);
          const keywords: MetaDefinition = {
            name: "keywords",
            content: this.article.keywords
          }
          const description: MetaDefinition = {
            name: "description",
            content: this.article.description
          }
          if (this.article.description != undefined) {

            this.meta.updateTag(description);
          }
          if (this.article.keywords != undefined) {

            this.meta.updateTag(keywords);
          }

        });




      }
    });
  }

  mapArticle(art: BlogArticle): BlogArticle {

    const Entities = require('html-entities').AllHtmlEntities;

    const entities = new Entities();
    art.text = entities.decode(art.text);
    return art;
  }
}
