import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export class ConfimrMessageDefinition
{
    type:eDialogTypeConfirm;
    message:string;

}

export enum eDialogTypeConfirm {
  OK = 0,
  YesNo = 1,

}

@Component({
  selector: 'app-confim-message',
  templateUrl: './confim-message.component.html',
  styleUrls: ['./confim-message.component.scss']
})
export class ConfimMessageComponent implements OnInit {


  messageText: string;
  showCancel:boolean;


  constructor(
    public dialogRef: MatDialogRef<ConfimMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfimrMessageDefinition
  ) {

    this.messageText = data.message;
    this.showCancel = data.type == eDialogTypeConfirm.YesNo;
  }

  ngOnInit() {
  }


  action() {


    this.dialogRef.close(true);


  }

  cancel() {
    this.dialogRef.close(false);
  }

}
