import { Question } from './price-calculator/price-calculator.component';
import { inherits } from 'util';

export enum eAppType {
  Unknown = 0,
  WebApp = 1,
  MobileApp = 2,
  DesktoApp = 3,
  DataScience = 4,
  Reportinf = 5,
  CompanyProcess = 6,
  Hardware = 7,
  Tool = 8,
  Game = 9,
  Eshop = 10,


}

export class MailInfo {
  name: string;
  body: string;
  email: string;
  definition: Question[];

}


export class OfferBase {
  name: string;
  description: string;
  email: string;
  definition: Question[];

}



export class ProjectOfferRequest extends OfferBase {

  projectType:eAppType;
}

export class UserOfferRequest extends OfferBase {

  project: ProjectOfferDisplay;

  
}



export class  ProjectOfferDisplay {
  projectOfferId: number;
  price: number;
  userPrice: number;
  currency: string;
  created: string;
  endDate: string;
  email: string;
  name: string;
  description: string;
  definition: Question[];
  note: string;
  approved: boolean;
  userId: number;
  state: eOfferState;
  projectType:eAppType;
  offers:OfferBase[]
}

export enum eOfferState
{
  Offered = 0,
  Confirmed = 1,
  InProggres= 2,
  Done = 3
}

