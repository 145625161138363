import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Question } from '../price-calculator/price-calculator.component';
import { UserService } from '../users/user.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../contact/contact.component';
import { ProjectOfferRequest, UserOfferRequest, ProjectOfferDisplay } from '../models';
import { ApiService } from '../services/api.service';
import { ProjectService } from '../services/project.service';


@Component({
  selector: 'app-user-offer',
  templateUrl: './user-offer.component.html',
  styleUrls: ['./user-offer.component.scss']
})
export class UserOfferComponent implements OnInit {


  @Output() cancelClicked = new EventEmitter();
  @Output() actionClicked = new EventEmitter();





  @Input() isLoged: boolean;

  @Input() isUserOffer: boolean;

  @Input() project: ProjectOfferDisplay;

  matcher = new MyErrorStateMatcher();
  contactForm: FormGroup;
  get f() { return this.contactForm.controls; }

  constructor(private userService: UserService, private fb: FormBuilder, private projectService: ProjectService) { }

  ngOnInit() {
    this.userService.isLoged.subscribe(x => this.isLoged = x);

    this.contactForm = this.fb.group({
      nameFormControl: ['', [Validators.required]],
      emailFormControl: ['', [Validators.required, Validators.email]],
      textFormControl: ['', [Validators.required]]
    });
  }

  action() {



    let obj = new UserOfferRequest();
    obj.email = this.f.emailFormControl.value;
    obj.name = this.f.nameFormControl.value;
    obj.description = this.f.textFormControl.value;
    obj.definition = this.project.definition;
    obj.project = this.project;


    this.projectService.createOffer(obj);

    this.actionClicked.emit(null);
  }

  cancel() {

    this.cancelClicked.emit(null);
  }

}
