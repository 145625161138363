import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { FilterModel, ProjectFilter } from '../shared/filter.model';
import { ProjectOfferDisplay } from '../models';
import { PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  projects: ProjectOfferDisplay[];

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;
  filterModel:ProjectFilter;

  constructor(private projectService: ProjectService, private router: Router, private common:CommonService) {
    this.filterModel = new ProjectFilter();

   }

  ngOnInit() {
   this.filter();
  }

  translate(project:ProjectOfferDisplay):string
  {
      return this.common.getProjectTranslation(project.projectType);
  }

  filter() {
 

    if (this.pageEvent !== undefined) {
      this.filterModel.take = this.pageEvent.pageSize;

      this.filterModel.page = this.pageEvent.pageIndex;
    }

    



    this.projectService.getAll(this.filterModel).subscribe(x => { this.projects = x.entities; this.length = x.maxRows;});
  }

  pageChange(event:PageEvent)
  {
   this. pageEvent = event;
   this.filter();
  }

  scrollTo(id: string) {




    this.router.navigate(["/contact"]);

  }

}
