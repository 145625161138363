import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { eAppType } from '../models';
import { Question } from '../price-calculator/price-calculator.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionService extends ApiService  {

  constructor(http: HttpClient) {
    super(http);
    this.controller = "question";
  }

  getQuestions( appType:eAppType):Observable<Question[]>
  {
    const api =""+appType;
    return this.get<Question[]>(api);
  }


}
