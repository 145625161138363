import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';

import  '../assets/script.js';
import './icons.ts';

import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.js';
declare var scrollToBookmark: any;

import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs';
import { Router } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent   implements OnInit, OnDestroy {
  title = 'estimate app price';

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;


  @Input()
  inputMessage:string;


  public constructor(private translate: TranslateService,
      titleService: Title, private meta: Meta, private ccService: NgcCookieConsentService,private router:Router)
  {
    this.inputMessage = environment.api;

    translate.addLangs(   ["en","sk-SK"]);
    translate.setDefaultLang('en');
    titleService.setTitle("EstimateAppPrice.com - Estimate your application price");

    var lang = translate.getBrowserCultureLang();


  
    //translate.use(lang);

    console.log(environment.production,"ip");

  }
  
  switchLanguage(language: string) {
    this.translate.use(language);
  }

  onActivate(e) {

    window.scrollTo(0, 0);
  }


  ngOnInit() {
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializingSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
     //   console.log(`initializing: ${JSON.stringify(event)}`);
      });
    
    this.initializedSubscription = this.ccService.initialize$ .subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
       // console.log(`initialized: ${JSON.stringify(event)}`);
      });

    // this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
    //   (event: NgcInitializationErrorEvent) => {
    //     // the cookieconsent has failed to initialize... 
    //     console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
    //   });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
       if(event.status == "deny")
       {

        window.location.href =  "http://www.google.com";
       }
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
     //   console.log(event,"decline");
      });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }


}
