import { Component, OnInit } from '@angular/core';
import { BlogArticle, eBlogType } from '../blog-article.model';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-blog-articles-list',
  templateUrl: './blog-articles-list.component.html',
  styleUrls: ['./blog-articles-list.component.scss']
})
export class BlogArticlesListComponent implements OnInit {

  articleList:BlogArticle[];
  selectedArticle:BlogArticle;


  constructor(private blogService:ApiService, private acitvatedRoute: ActivatedRoute, private router: Router) {

    this.articleList = [];
    this.selectedArticle = new BlogArticle();
   }

   ngOnInit() {


    this.blogService.getContent().subscribe(res => {
      let tmp = res.body.filter(x => x.type == eBlogType.Information);
      this.FillToStructure(tmp);

    }
    );





    this.acitvatedRoute.params.subscribe(params => {
      let id = params['seo_url'] as string;
      if (  id!=undefined && id.length > 0) {
        this.loadArticle(id);
      }
    });



  }

  private FillToStructure(tmp: BlogArticle[]) {

    let twoDim: any[][] = [];
    let counter = 0;
    for (let main of tmp.filter(art => art.parent == -1)) {
      twoDim[main.id] = [];
      twoDim[main.id].push(main);
      let articles = tmp.filter(art => art.parent == main.id).sort(art => art.sortOrder);
      for (let subArticle of articles) {
        twoDim[main.id].push(subArticle);
      }
    }
    this.articleList = [];
    for (let artForCat of twoDim) {
      if (artForCat != undefined) {
        artForCat.forEach(article => this.articleList.push(article));
      }
    }
  }

  loadArticle(seo_url: string) {

        this.router.navigate(["/examples/"+seo_url]);
  }
}
