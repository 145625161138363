import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Question, Answer } from '../price-calculator/price-calculator.component';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource, MatAccordion } from '@angular/material';
import { QuestionService } from '../services/question.service';



@Component({
  selector: 'app-project-question-tree',
  templateUrl: './project-question-tree.component.html',
  styleUrls: ['./project-question-tree.component.scss']
})
export class ProjectQuestionListComponent implements OnInit {

  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;

  @Input()
  questions: Question[];

  @Input() readonly:boolean;

  @Output() questionsChange = new EventEmitter<Question[]>();


  questionsWithValue:Question[]

  constructor() { }


  ngOnInit() {

    // this.questionsWithValue = this.questions.filter(q=>q.offerValue);
    console.log(this.questions);
  }

  showPrice(question:Question)
  {
    return     this.questionsWithValue.indexOf(question)>-1;
  }

}
