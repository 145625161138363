import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Question } from '../price-calculator/price-calculator.component';
import { UserService } from '../users/user.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../contact/contact.component';
import { ProjectOfferRequest, UserOfferRequest, ProjectOfferDisplay, eAppType } from '../models';
import { ApiService } from '../services/api.service';
import { ProjectService } from '../services/project.service';



@Component({
  selector: 'app-send-offer',
  templateUrl: './send-offer.component.html',
  styleUrls: ['./send-offer.component.css']
})
export class SendOfferComponent implements OnInit {



  @Output() cancelClicked = new EventEmitter();
  @Output() actionClicked = new EventEmitter();



  @Input() steps: Question[];

  @Input() projectType: eAppType;

  @Input() isLoged: boolean;

  @Input() isUserOffer: boolean;

  @Input() project: ProjectOfferDisplay;

  matcher = new MyErrorStateMatcher();
  contactForm: FormGroup;
  get f() { return this.contactForm.controls; }

  constructor(private userService: UserService, private fb: FormBuilder, private projectService: ProjectService) { }

  ngOnInit() {
    this.userService.isLoged.subscribe(x => this.isLoged = x);

    this.contactForm = this.fb.group({
      nameFormControl: ['', [Validators.required]],
      emailFormControl: ['', [Validators.required, Validators.email]],
      textFormControl: ['']
    });
  }

  action() {


    if (this.contactForm.valid) {


      if (this.isUserOffer) {
        let obj = new UserOfferRequest();
        obj.email = this.f.emailFormControl.value;
        obj.name = this.f.nameFormControl.value;
        obj.description = this.f.textFormControl.value;
        obj.definition = this.steps;
        obj.project = this.project;

        this.projectService.createOffer(obj);
      }
      else {

        let obj = new ProjectOfferRequest();
        obj.email = this.f.emailFormControl.value;
        obj.name = this.f.nameFormControl.value;
        obj.description = this.f.textFormControl.value;
        obj.definition = this.steps;
        obj.projectType = this.projectType;

        this.projectService.createProject(obj);
      }

      this.actionClicked.emit();
    }
  }

  cancel() {

    this.cancelClicked.emit();
  }

}
