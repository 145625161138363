import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute } from '@angular/router';
import { UserDisplay, FreelancingDisplay } from '../user.model';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  user: UserDisplay;
  name: string;

  constructor(private route: ActivatedRoute, private userservice: UserService) {

    this.user = new UserDisplay();
  }
  
  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['id'];
      
      this.userservice.showDetail(id).subscribe(x => {
        this.user = x;
        
        this.name = x.name;
        console.log(x);

      });

    });
  }

  contact(id: number) {


    this.userservice.contact(id);
  }

}
