import { eAppType } from '../models';

export class FilterModel implements IFilterModel {


    sortColl: number;


    page: Number = 0;

    take: Number = 10;

    search: String;

}

export interface IFilterModel {

    page: Number;

    take: Number;

    search: String;
    sortColl: number;

}


export class DevelopersFilter extends FilterModel {

constructor()
{
    super();
    this.sortColl = 0;
}

    skils: number[];

}

export class ProjectFilter extends FilterModel {

    constructor()
    {
        super();
        this.sortColl =0;
        this.projecType = eAppType.Unknown;
    }


    minPrice: Number;
    maxPrice: Number;

    projecType: eAppType;

}
