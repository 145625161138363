





import { Router } from '@angular/router';
import { User, UserDisplay, UserSettings, FreelancingDisplayAttribute, FreelancingDisplay, LogedUserInfo, FilterTable, UserContact } from './user.model';
import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { promise } from 'protractor';
import { map } from 'rxjs/operators';
import { Console } from 'console';
import { ApiService } from '../services/api.service';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { FilterModel } from '../shared/filter.model';
import { MatDialog } from '@angular/material';
import { SimpleInputComponent } from '../shared/simple-input/simple-input.component';
import { CommonService } from '../services/common.service';





@Injectable({
    providedIn: 'root'
})
export class UserService extends ApiService implements OnInit {


    public CURRENT_USER = 'currentUser';
    private CURRENT_PASSPHRASE = 'currentPassphrase';


    controller;

    isLoged: BehaviorSubject<boolean>;
    logedUser: BehaviorSubject<LogedUserInfo>

    constructor(http: HttpClient, private router: Router, private dialog: MatDialog, private commonService: CommonService) {
        super(http);
        this.controller = "user";
        this.isLoged = new BehaviorSubject(false);
        this.logedUser = new BehaviorSubject(new LogedUserInfo());

    }
    ngOnInit(): void {
        this.isAuthenticated();
    }

    login(username: string, password: string,license:boolean): Observable<LogedUserInfo> {
        let ret = new User();
        ret.password = password;
        ret.email = username;
        ret.license = license;



        return this.post<LogedUserInfo>(`login`, ret).pipe(map(x => {

            if (x.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes


                localStorage.setItem(this.CURRENT_USER, JSON.stringify(x));
            }

            this.isAuthenticated();
            return x;
        }
        ));


    }



    logout(returnUrl?: String) {

        localStorage.removeItem(this.CURRENT_USER);
        localStorage.removeItem(this.CURRENT_PASSPHRASE);

        this.isAuthenticated();
        if (returnUrl != undefined) {

            this.router.navigate([returnUrl])
        }

    }

    getToken() {
        let currentUser = this.getUser();
        if (currentUser && currentUser.token) {
            return currentUser.token;
        }


        return "";
    }

    getUser(): LogedUserInfo {
        let currentUser = JSON.parse(localStorage.getItem(this.CURRENT_USER));



        return currentUser;
    }

    public isAuthenticated(): boolean {
        // get the token
        let user = this.getUser();
 
        var isLoged = user != null && user.token.length > 0;
    
        this.isLoged.next(isLoged);
        this.logedUser.next(user);
        return isLoged;
    }

    create(login: string, password: string,license:boolean) {
        let ret = new User();
        ret.email = login;
        ret.password = password;
        ret.license = license;

        return this.post<any>(`register`, ret);
    }

    changePassword(password: string) {
        let ret = new User();
        ret.password = password;

        return this.post<any>(`changePassword`, ret);
    }

    renewPassword(login: string) {
        let ret = new User();
        ret.email = login;


        return this.post<any>(`renewPassword`, ret);
    }


    getKnowledge(): Observable<FreelancingDisplayAttribute[]> {

        const api = `GetKnowledge`;
        return this.get<FreelancingDisplayAttribute[]>(api);
    }



    showDetail(id: number): Observable<UserDisplay> {

        const api = `get/${id}`;
        return this.get<UserDisplay>(api);
    }


    getSettings(): Observable<UserSettings> {

        var user = this.getUser();
        const api = `settings/${user.userId}`;
        return this.get<UserSettings>(api);
    }

    saveSettings(settings: UserSettings): Observable<UserSettings> {

        var user = this.getUser();
        const api = `saveSettings`;
        return this.post<UserSettings>(api, settings);
    }

    getDevelopers(model: FilterModel): Observable<FilterTable<FreelancingDisplay>> {

        const api = `GetDevelopers`;
        return this.post<FilterTable<FreelancingDisplay>>(api, model);
    }

    contact(id: number) {


        const api = "contactFreelancer";

        var user = new UserContact();
        user.userId = id;

     
        if (!this.isAuthenticated()) {

 
            let dialogRef = this.dialog.open(SimpleInputComponent, {

                width: '600px',
                data: {

                    "messageText": "Enter contact email",
                }

            });


            dialogRef.afterClosed().subscribe(
                data => {

                    if (data != null) {


                        user.email = data;

         

                        this.post<boolean>(api, user).subscribe(x => {
                            this.router.navigate(['developer-contacted']);
            
                        });
                    }

                }
            );

        }
        else {



            this.post<boolean>(api, user).subscribe(x => {
                this.router.navigate(['developer-contacted']);

            });
        }



    }


}