import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ApiService} from '../services/api.service';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Question } from '../price-calculator/price-calculator.component';
import { MailInfo } from '../models';
import { CommonService } from '../services/common.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);

  // nameFormControl = new FormControl('', [
  //   Validators.required
  // ]);
  private val: string;


  @Input() steps: Question[];

  @Input()

  get inputMessage(): string {
    return this.val;
  }
  set inputMessage(inp: string) {
    this.val = inp;
    this.focus();
  }

  @ViewChild('textarea2', { static: false }) contactInput: ElementRef;
  
  matcher = new MyErrorStateMatcher();
  contactForm: FormGroup;
  get f() { return this.contactForm.controls; }

  constructor(private fb: FormBuilder,
    private api: CommonService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    this.contactForm = this.fb.group({
      nameFormControl: ['', [Validators.required]],
      emailFormControl: ['', [Validators.required, Validators.email]],
      textFormControl: ['', [Validators.required]]
    });

  }


  focus() {

    if (this.val != undefined && this.val.length > 0) {

      this.contactInput.nativeElement.focus();
      this.contactInput.nativeElement.setSelectionRange(0, 0);

    }

  }

  submitForm() {

    if (this.contactForm.invalid) {
      return;
    }

    let obj = new MailInfo();
    obj.email = this.f.emailFormControl.value;
    obj.name = this.f.nameFormControl.value;
    obj.body = this.f.textFormControl.value;
    obj.definition = this.steps;

     this.api.sendEmail(obj);





    const tran = this.translate.instant('Contact.Sended');

    this.snackBar.open(tran, "Email", {
      duration: 3000,
      verticalPosition: 'top'
    });

  }



}
