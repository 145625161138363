import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-simple-input',
  templateUrl: './simple-input.component.html',
  styleUrls: ['./simple-input.component.scss']
})
export class SimpleInputComponent implements OnInit {

  messageText: string;
  result: string;
  

  constructor(public dialogRef: MatDialogRef<SimpleInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.messageText = data.messageText;
  }

  ngOnInit() {
  }



  action() {


    this.dialogRef.close(this.result);


  }

  cancel() {
    this.dialogRef.close(false);
  }

}
