import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgAnimateScrollService } from 'ng-animate-scroll';

import { AppComponent } from './app.component';
import { PricingComponent } from './pricing/pricing.component';
import { ProjectTypesComponent } from './project-types/project-types.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule, MatCardModule, MatIconModule, MatFormFieldModule, MatInputModule, MatSnackBarModule, MatTableModule, MatStepperModule, MatRadioModule, MatTooltipModule, MatDialogModule, MatSpinner, MatProgressSpinnerModule, MatPaginatorModule, MatTreeModule, MatAccordion, MatExpansionModule, MatExpansionPanel, MatChipsModule, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkColumnDef } from '@angular/cdk/table';


import { PriceCalculatorComponent } from './price-calculator/price-calculator.component';
import { CommonModule } from '@angular/common';
import { BlogArticleComponent } from './blog/blog-article/blog-article.component';
import { BlogArticlesListComponent } from './blog/blog-articles-list/blog-articles-list.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { ExamplesComponent } from './examples/examples.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { CookieLawModule } from 'angular2-cookie-law';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { CookieService } from "angular2-cookie/services/cookies.service";
import { SendOfferComponent } from './send-offer/send-offer.component';
import { TokenInterceptor } from './services/token.interceptor';
import { UserService } from './users/user.service';
import { CommonService } from './services/common.service';
import { ErrorInterceptor } from './services/error.interceptor';
import { LoginComponent } from './users/login/login.component';
import { RegisterComponent } from './users/register/register.component';
import { StringFormatPipe } from './shared/pipes/string-format.pipe';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectQuestionListComponent } from './project-question-tree/project-question-tree.component';
import { UserOfferComponent } from './user-offer/user-offer.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';
import { UserFreelancingComponent } from './user-freelancing/user-freelancing.component';
import { DevelopmentComponent } from './development/development.component';
import { MessageCenterComponent } from './message-center/message-center.component';
import { ItManagerComponent } from './it-manager/it-manager.component';
import { SimpleInputComponent } from './shared/simple-input/simple-input.component';
import { ConfimMessageComponent } from './shared/confim-message/confim-message.component';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';



const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#003399'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>. 
    </span>
    `,
  },
  content:{
    message: 'By using our site, you acknowledge that you have read and understand our ',
    
    cookiePolicyLink: 'license conditions and privacy policy',
    cookiePolicyHref: 'http://estimateappprice.com/license.pdf',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://privacy.com',
  }
};



@NgModule({
  declarations: [
    AppComponent,
    PricingComponent,
    ProjectTypesComponent,
    IntroductionComponent,
    ContactComponent,
    AboutUsComponent,
    NavigationComponent,
    FooterComponent,
    PriceCalculatorComponent,
    BlogArticleComponent,
    BlogArticlesListComponent,
    HomeComponent,
    ExamplesComponent,
    SendOfferComponent,
    LoginComponent,
    RegisterComponent,
    StringFormatPipe,
    ProjectsComponent,
    ProjectDetailComponent,
    ProjectQuestionListComponent,
    UserOfferComponent,
    UserDetailComponent,
    UserSettingsComponent,
    UserFreelancingComponent,
    DevelopmentComponent,
    MessageCenterComponent,
    ItManagerComponent,
    SimpleInputComponent,
    ConfimMessageComponent,
    TruncatePipe
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    CommonModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
   MatInputModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTableModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTreeModule,
    MatExpansionModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatButtonModule,
    MatSelectModule,

    HttpClientModule,
    CookieLawModule,
    ShareButtonsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule
  ],
  
  exports :[    TranslateModule],
  entryComponents: [
    SendOfferComponent,LoginComponent,RegisterComponent,UserSettingsComponent, SimpleInputComponent
  ],
  providers: [NgAnimateScrollService,CdkColumnDef, CookieService,UserService,CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 


}




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

