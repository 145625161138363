import { Component, OnInit } from '@angular/core';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        'height': '200px',
        'visibility': 'visible',

      })),
      state('closed', style({
        'height': '0px',
        'visibility': 'hidden',

      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
    // animation triggers go here
  ]
})
export class PricingComponent implements OnInit {


  aditionalInfo: boolean;
  aditionalInfoText: string;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.aditionalInfo = false;
  }



  openDesc() {

    this.aditionalInfo = !this.aditionalInfo;

    this.SetTextAditional();


  }


  private SetTextAditional() {

    if (this.aditionalInfo) {
      setTimeout(() => {
        const text1 = this.translate.instant('Pricing.Samples1');
        const text2 = this.translate.instant('Pricing.Samples2');
        const text3 = this.translate.instant('Pricing.Samples3');
        this.aditionalInfoText = `${text1} \n\n${text2}\n\n${text3}`;
      },
        600);
    }
    else {
      this.aditionalInfoText = "";
    }

  }

  animStart(event) {
  
    //
  }

  animEnd(event) {

    // this.SetTextAditional();
  }
}
