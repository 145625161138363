import { OfferBase } from '../models';

export class User {
    name: string;
    password: string;
    email: string;
    license:boolean;

}

export class UserDisplay {
    userId: number;
    name: string;
    description: string;
    type: eUserType;
    offers: OfferBase[];
    created: Date;
    isDeveloper: boolean;
    userFreelancing: FreelancingDisplay;
}



export class LogedUserInfo {
    userId: number;
    token: string;
    email: string;
    type: eUserType;
}

export class UserSettings {
    constructor() {
        this.projects = [];
        this.userFreelancing = new FreelancingDisplay();
    }

    userId: number;
    name: string;
    description: string;
    email: string;
    notificationEmail: string;
    projects: OfferBase[];
    type: eUserType;
    isDeveloper: boolean;
    userFreelancing: FreelancingDisplay;
    offers:OfferBase[];
}

export class FilterTable<T>
{
    entities: T[];

    maxRows: number;
}

export class UserContact {

    email: string
    userId: number;
}


export class FreelancingDisplay {

    constructor() {
        this.rateType = eFreelanceRateType.Month;
        this.freelancingAttributes = [];
        this.currency = "USD";
    }

    userFreelancingId: number;
    price: number;
    currency: string;
    rateType: eFreelanceRateType;
    type: eUserType;
    availableFrom: string;
    availableTo: string | null;
    expirience: number;
    projectCount: number;
    userId: number;
    name: string;
    description: string;
    freelancingAttributes: FreelancingDisplayAttribute[];
}

export class FreelancingDisplayAttribute {
    estimateAttributeId: number;
    name: string;
    freelancingAttributeId: number;
    userFreelancingId: number;
    level: eFreelancingLevel;
}

export enum eFreelancingLevel {
    None = 0,
    Beginner = 1,
    Intermediate = 2,
    Expert = 3
}

export enum eFreelanceRateType {
    Hour = 0,
    Day = 1,
    Month = 2
}

export enum eUserType {
    Person = 0,
    Company = 1,
    Team = 2,
}