import { Injectable, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
//import { HttpProvider } from '../httpProvider.service';

import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { BlogArticle } from '../blog/blog-article.model';
import { Question as Question } from '../price-calculator/price-calculator.component';
import { MailInfo, eAppType, ProjectOfferRequest } from '../models';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ApiService {


  controller: string

  constructor(private http: HttpClient) { }

  public get<T>(url: string, p: HttpParams = null): Observable<T> {
  
    var full = environment.api + this.controller +"/"+ url;
 
    return this.http.get<T>(full, { observe: 'response', params: p }).pipe(
      map(response => response.body)

    );
  }

  // public put<T>(url: string, object: T): Observable<HttpResponse<T>> {
  //     return this.http.put<T>(url, object)
  //         .catch(error => this.handleError(error));
  // }

  public post<T>(url: string, object: any): Observable<T> {


    var full = environment.api + this.controller +"/"+ url;
    return this.http.post<T>(full, object, { observe: 'response' }).pipe(
      map(response => response.body)

    );;

  }

  getContent(p: HttpParams = null): Observable<HttpResponse<BlogArticle[]>> {
    const getdata = "blog/getContent.php";

    return this.http.get<BlogArticle[]>(getdata, { observe: 'response', params: p });

  }


  getArticle(id: string): Observable<BlogArticle> {
    const api = "blog/getArticle.php?id=" + id;
    return this.http.get<BlogArticle>(api);
  }




}
