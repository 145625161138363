import { eFreelanceRateType } from "./users/user.model";

export class Utils {


    static GetRateTypeString(type: eFreelanceRateType): string {
        if (type === eFreelanceRateType.Day) {
            return "Day";
        }
        if (type === eFreelanceRateType.Month) {
            return "Month";


        }

        return "Hour";

    }
}