import { Injectable, OnInit } from '@angular/core';
import { ProjectOfferRequest, ProjectOfferDisplay, UserOfferRequest } from '../models';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { IFilterModel as IFilter, FilterModel } from '../shared/filter.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FilterTable } from '../users/user.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ApiService  {

  constructor(http: HttpClient, private router:Router) {
    super(http);
    this.controller = "project";
  }




  createProject(obj: ProjectOfferRequest) {


    const api = "add";



    this.post<number>(api, obj).subscribe(x => {

      this.router.navigate(['project-created']);

    });
  }

  createOffer(obj: UserOfferRequest) {


    const api = "addOffer";


    this.post<number>(api, obj).subscribe(x => {

      this.router.navigate(['offer-created']);

    });
  }


  acceptOffer(id:number) {


    const api = "acceptOffer/"+id;


    this.get<number>(api).subscribe(x => {

      this.router.navigate(['offer-accepted']);

    });
  }


  removeProject(id:number) {


    const api = "delete/"+id;


    this.get<number>(api).subscribe(x => {

      this.router.navigate(['project-removed']);

    });
  }


  getAll(filter:IFilter) :Observable< FilterTable< ProjectOfferDisplay>>{


    

    const api = "getAll";

    return this.post< FilterTable< ProjectOfferDisplay>>(api, filter);

  }

  ;
  showDetail(id:string) :Observable<ProjectOfferDisplay>{


    const number = id.split('-')[0];

    const api = `get/${number}`;


   return this.get<ProjectOfferDisplay>(api);
  }

}
