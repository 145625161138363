import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';
import { MailInfo } from '../models';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss']
})
export class MessageCenterComponent implements OnInit {

  messageType: Number;

  constructor(private activatedroute: ActivatedRoute, private commonServie: CommonService) { }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => {

      this.messageType = data.messageType;
    });

    this.activatedroute.queryParams.subscribe(params => {
      let id = params['reference'];

      if (id != null) {
        this.messageType = 5;
        var mail = new MailInfo();
        mail.name = "reference";
        mail.body = id;
        this.commonServie.sendEmail(mail).subscribe(x => x);
      }

    });





  }

}
