import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectOfferDisplay } from '../models';
import { ProjectService } from '../services/project.service';
import { Question } from '../price-calculator/price-calculator.component';
import { UserService } from '../users/user.service';
import { BehaviorSubject } from 'rxjs';
import { LogedUserInfo } from '../users/user.model';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  project: ProjectOfferDisplay;

  logedUser:BehaviorSubject<LogedUserInfo>;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, private userService:UserService) {

    this.project = new ProjectOfferDisplay();
    this.logedUser = this.userService.logedUser;

  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['fullUrl'];



      this.projectService.showDetail(id).subscribe(x => {
        this.project = x;
        this.project.definition.forEach(q => this.recalculateQuestions(q));

      });

    });
  }

  recalculateQuestions(question: Question) {



    var selected = question.answers.filter(a => a.selected);
    question.offerValue = selected.reduce((sum, current) => sum + current.value, 0);
    question.offerNote = "";


  }


  acceptOffer(id: number) {
    this.projectService.acceptOffer(id);

  }

  delete() {
    this.projectService.removeProject(this.project.projectOfferId);

  }

}
