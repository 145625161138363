import { Component, OnInit, Input } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { Router } from '@angular/router';
import { UserService } from '../users/user.service';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../users/login/login.component';
import { RegisterComponent } from '../users/register/register.component';
import { UserSettingsComponent } from '../users/user-settings/user-settings.component';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() isLoged:boolean;
  


  constructor(private animateScrollService: NgAnimateScrollService, private router: Router,private userService:UserService , private dialog: MatDialog,) { }




  ngOnInit() {
      this.isLoged = this.userService.isAuthenticated();
      this.userService.isLoged.subscribe(x=> this.isLoged = x);
  }

  login() {
    let dialogRef = this.dialog.open(LoginComponent, {

      width: '600px',
      data:{  
      }

    });

    dialogRef.afterClosed().subscribe(
      data => {

        if (data != null) {

        }

      }
    );
}

settings() {
  let dialogRef = this.dialog.open(UserSettingsComponent, {

    width: '600px',
    data:{  
    }

  });

  dialogRef.afterClosed().subscribe(
    data => {

      if (data != null) {

      }

    }
  );
}


register() {
  let dialogRef = this.dialog.open(RegisterComponent, {

    width: '600px',
    data:{

    
    }

  });


  dialogRef.afterClosed().subscribe(
    data => {

      if (data != null) {

      }

    }
  );
}

logout()
{
  this.userService.logout();

}

  scrollTo(id:string)
  {


    var curUrl = this.router.url;


    var targetUrl = `/${id}`;


    if (curUrl == `/home`) {
      if (id == "home") {
        id="introduction";
      }

      let element = document.getElementById(id);
      if (element != undefined  && element != null) {
        this.animateScrollService.scrollToElement(id, 750);
      }
      else {
        this.router.navigate([targetUrl]);
      }
    }
    else {
      let element = document.getElementById(id);

      if (element == undefined || element == null) {
        this.router.navigate([targetUrl]);
      }
      else
      {        
        this.animateScrollService.scrollToElement(id, 750);
      }
    }

  }



}
